import React from 'react'
import { NextPage } from 'next'
import { Button, Grid, Theme, Typography } from '@material-ui/core'
import { BaseCSSProperties, makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { StartonLink } from 'components/Core/StartonLink'
import { StartonKYCPannel } from 'components/Core'
import { OnBoardingLayout } from 'containers/onBoarding'
import useUser from 'data/user'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface StyleProps {
	container: BaseCSSProperties
	button: BaseCSSProperties
}
type PropClasses = Record<keyof StyleProps, string>
type StyleClassKey = 'container' | 'button'

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>({
	container: {
		width: '100%',
		height: '100vh',
	},
	button: {
		marginTop: '2em',
	},
})

/*
|--------------------------------------------------------------------------
| PAGE
|--------------------------------------------------------------------------
*/
const StartonPageNoAuthorized: NextPage = () => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()

	React.useEffect(() => {
		enqueueSnackbar(t('tools.not_autorized_page'), {
			variant: 'error',
		})
	}, [])

	const { userError } = useUser()

	return (
		<Grid container direction="row" className={classes.container}>
			<StartonKYCPannel />
			{userError && (
				<Grid item xs={12} sm={12} md={12} lg={9}>
					<OnBoardingLayout>
						<Typography variant="h4">{t(`tools.${userError?.response?.data?.errorCode}`)}</Typography>
						{/*<Typography className="mb-6 text-xl">{t('tools.error_412')}</Typography>*/}
						{userError?.response?.data?.errorCode === 'LemonwayExpiredOnBoardingIdException' && (
							<Button
								className={classes.button}
								component={StartonLink}
								href={userError?.response?.data?.message}
								color="primary"
							>
								{t('tools.return_platform')}
							</Button>
						)}
					</OnBoardingLayout>
				</Grid>
			)}
		</Grid>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export default StartonPageNoAuthorized
